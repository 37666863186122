<template>
    <div class="box p-3 box-min">
        <pie-chart :chartdata="chartdata" :options="options" />
        <p>
            {{numberOfViews}} Impressões
        </p>
        <span>Total de pessoas que visualizaram</span>
    </div>
</template>

<script>
export default {
    components: {
      'pie-chart': () => import('./Pie.vue'),
    },
    props: {
        numberOfViews: {
            type: Number,
            required: true
        },
        chartdata: {
            type: Object,
            required: true
        },
        options: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>
.box{
  width: 330px;
  background-color: #e6e7e9;
  border-radius: 20px;
  height: 100%;
  min-height: 330px;
}
.box-min {
  width: 100%;
  height: 100%;
  max-width: 250px;
  min-height: 338px;
  text-align: center;
}
</style>